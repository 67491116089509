<template>
    <div class="container">
        <b-card>
            <b-form @submit.prevent="Sendform()" class="account-form">
                <fieldset class="bg-light p-4">
                    <h4 class="mb-2">Loan Information</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="DDLTypeLoan">Loan Type</label>
                                <b-form-select v-model="LoanType" class="mb-3" id="DDLTypeLoan" name="DDLTypeLoan"
                                    v-on:change="GetMinAndMaxAmount()" required>
                                    <b-form-select-option :value="null">Please select an option</b-form-select-option>
                                    <b-form-select-option value="1">Personal</b-form-select-option>
                                    <b-form-select-option value="2">Vehicle</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="DDLPeriod">Period in Months</label>
                                <b-form-select v-model="MonthSelectedOption" class="mb-3" id="DDLPeriod"
                                    name="DDLPeriod" v-on:change="GetMonthlyPayment()" required>
                                    <b-form-select-option v-for="Month in MonthsArray" :key="Month" :value="Month">{{
                                        Month
                                    }}</b-form-select-option>
                                </b-form-select>
                            </div>

                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="LoanAmount">Loan Amount</label>
                                <b-input type="number" class="WhitePlaceHolder" id="LoanAmount" name="LoanAmount"
                                    v-model="Amount" v-bind:min="MinAmount" v-bind:max="MaxAmount"
                                    v-on:input="Debounce()" placeholder="" required />
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="MonthlyPayment">
                                Monthly Payment:
                                <div v-if="Loading" class="spinner-border spinner-border-sm"></div>
                            </label>

                            <b-input type="number" class="WhitePlaceHolder" id="MonthlyPayment" name="MonthlyPaCment"
                                v-model="MonthlyPayment" placeholder="AWG 0.00" readonly="readonly" />
                        </div>
                    </div>

                </fieldset>

                <fieldset class="p-4">
                    <h4 class="mb-2"><strong></strong>Customer Information</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-2">
                                <label class="h6">Search an customer</label>
                                <Select :items="options" @option-selected="handleOption"
                                    @search-input="handleSearchInput" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Identification"><strong>ID</strong></label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="Identification" name="Identification"
                                        required />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="FirstName"><strong>First Name</strong></label>
                                <b-input type="text" id="FirstName" name="FirstName" required />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="LastName"><strong>Last Name</strong></label>
                                <b-input type="text" id="LastName" name="LastName" required />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Email"><strong>Email Address</strong></label>
                                <b-input type="email" id="Email" name="Email" required />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Telephone"><strong>Phone Number</strong></label>
                                <b-input type="text" id="Telephone" name="Telephone" required />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Address"><strong>Address</strong></label>
                                <b-input type="text" id="Address" name="Address" required />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="EmployeerName"><strong>Employer Name</strong></label>
                                <b-input type="text" id="EmployeerName" name="EmployeerName" required />
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label><strong>Estado Civil</strong></label>
                                <b-form-radio-group v-model="MaritalStatus" v-slot="{ ariaDescribedby }" required>
                                    <b-form-radio :aria-describedby="ariaDescribedby" name="MaritalStatus" id="opcion1"
                                        value="1">Casa</b-form-radio>
                                    <b-form-radio :aria-describedby="ariaDescribedby" name="MaritalStatus" id="opcion2"
                                        value="2">Soltero/-a</b-form-radio>
                                    <b-form-radio :aria-describedby="ariaDescribedby" name="MaritalStatus" id="opcion3"
                                        value="3">Biba Hunto</b-form-radio>
                                    <b-form-radio :aria-describedby="ariaDescribedby" name="MaritalStatus" id="opcion4"
                                        value="4">Otro</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>

                        <div class="col-md-12" v-if="MaritalStatus == 1">
                            <div class="form-group">
                                <label for="SpocesFullName"><strong>Spouse's first and last name</strong></label>
                                <b-input type="text" id="SpocesFullName" name="SpocesFullName" v-model="SpoucesFullName"
                                    placeholder="Type the name..." required />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label for="bankEntities"><strong>Bo persona tin debe existente? Na
                                        unda?</strong></label>

                                <b-form-radio-group v-model="SelectedBankEntities" class="row mx-1" required>
                                    <b-form-radio v-for="(item) in ListBankingEntities" :key="item.Id"
                                        name="bankEntities" :id="'bank-' + item.Id" :value="item.Id" class="col-3">{{
                                            item.Description }}</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>


                        <div class="col-md-6" v-if="SelectedBankEntities >= 1">
                            <div class="form-group">
                                <label for="ExistingDebtAmount"><strong>Amount of existing debt</strong></label>
                                <b-input type="number" id="ExistingDebtAmount" name="ExistingDebtAmount"
                                    v-model="ExistingDebtAmount" placeholder="AWG 0.00" />
                            </div>
                        </div>

                        <div class="col-md-6" v-if="SelectedBankEntities == 9">
                            <div class="form-group">
                                <label for="OtherInstitutionName"><strong>Name of institution</strong></label>
                                <b-input type="text" id="OtherInstitutionName" name="OtherInstitutionName"
                                    v-model="OtherInstitutionName" placeholder="Type the name..." />
                            </div>
                        </div>

                    </div>
                </fieldset>

                <fieldset class="bg-light p-4">
                    <h4 class="mb-4">Co-Applicant Information</h4>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-2">
                                <label class="h6">Search a customer</label>
                                <Select :items="optionsCoApp" @option-selected="handleOptionCoApplicant"
                                    @search-input="handleSearchCoApplicant" />
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="CoApplicantIdentification"><strong>Co-Applicant
                                        Identification</strong></label>
                                <b-input type="text" id="CoApplicantIdentification" name="CoApplicantIdentification" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="CoApplicantFirstName"><strong>Co-Applicant FirstName</strong></label>
                                <b-input type="text" id="CoApplicantFirstName" name="CoApplicantFirstName" />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="CoApplicantLastName"><strong>Co-Applicant LastName</strong></label>
                                <b-input type="text" id="CoApplicantLastName" name="CoApplicantLastName" />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="CoApplicantPhone1"><strong>Co-Applicant Phone</strong></label>
                                <b-input type="text" id="CoApplicantPhone1" name="CoApplicantPhone1" />
                            </div>
                        </div>

                        <!-- Customer documents -->
                        <div class="col-md-12">
                            <h4 class="mb-2">Customer Documents</h4>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="DocumentId"><strong>ID</strong></label>
                                <b-form-file name="DocumentId" id="DocumentId"
                                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                                    required></b-form-file>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Payslip"><strong>Payslip #1</strong></label>
                                <b-form-file name="Payslip" id="Payslip" placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."></b-form-file>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Others"><strong>Payslip #2</strong></label>
                                <b-form-file name="Others" id="Others" placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."></b-form-file>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Quote"><strong>Other (if applicable)</strong></label>
                                <b-form-file name="Quote" id="Quote" placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."></b-form-file>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row mt-4">
                    <div class="col-md-12 d-flex justify-content-end align-items-start">
                        <b-button type="submit" id="BtnSendForm" variant="primary" size="lg"
                            :disabled="loadingData">Send</b-button>
                    </div>
                </div>
            </b-form>

        </b-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
import { debounce } from 'lodash';
import Select from "../../components/Select.vue";

export default {
    name: "Apply",
    components: {
        Select
    },
    data() {
        return {
            UrlServerIP: 'https://partner.madworksglobal.com',
            UrlServerPORT: "",
            MaritalStatus: 0,
            SpoucesFullName: "",
            selectedOption: null,
            searchQuery: '',
            options: [],
            ListBankingEntities: [
                { Id: "0", Description: "No tin debe" },
                { Id: "1", Description: "Aruba Bank" },
                { Id: "2", Description: "Banco di Caribe" },
                { Id: "3", Description: "CMB" },
                { Id: "4", Description: "RBC" },
                { Id: "5", Description: "APFA" },
                { Id: "6", Description: "Volkskredietbank" },
                { Id: "7", Description: "FCCA" },
                { Id: "8", Description: "Island Finance" },
                { Id: "9", Description: "Other" },
            ],
            SelectedBankEntities: null,
            HasExistingDebt: false,
            ExistingDebtAmount: "",
            HasExistingDebtWithOther: false,
            OtherInstitutionName: "",
            AgreeTermsAndConditions: false,
            documentId: null,
            payslip1: null,
            payslip2: null,
            other: null,

            identificationType1: null,
            CoApplicantFirstName: null,
            CoApplicantLastName: null,
            CoApplicantPhone1: null,


            LoanType: null,
            Amount: 3500,
            MonthsArray: [],
            MonthSelectedOption: null,
            MinAmount: null,
            MaxAmount: null,
            MonthlyPayment: 0,
            Loading: false,
            loadingData: false,

            query: '',
            debouncedSearch: null,
            customers: [],
            coApplicants: [],
            optionsCoApp: [],
            firstNameCoApp: null,
            lastNameCoApp: null,
            idCoApp: null
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUser",
        }),
    },
    methods: {
        async sendLoanData(LoanApplicationId) {
            const url = "customers/Api/Profiles/AddDocuments";

            var formData = new FormData();
            formData.append("DocumentId", document.getElementById("DocumentId").files[0]);
            formData.append("Payslip", document.getElementById("Payslip").files[0]);
            formData.append("Others", document.getElementById("Others").files[0]);
            formData.append("Quote", document.getElementById("Quote").files[0]);
            formData.append("LoanApplicationId", LoanApplicationId);

            try {
                await axios.post(url, formData)

                this.$bvToast.toast(`Loan created successfully`, {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });

                const response = await this.$store.dispatch("applications/get_loanById", LoanApplicationId);

                const customerId = response?.dataResult?.customerId ?? 0;
                if (customerId > 0) {
                    this.$router.push(`/loans/applications/${customerId}?loanApplicationId=${LoanApplicationId}`);
                }

            } catch (error) {
                this.$bvToast.toast(`Error uploading documents`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }

        },
        async Sendform() {
            this.loadingData = true
            var payload = {
                id: 0,
                reasonTypeId: 1,
                firstName: document.getElementById("FirstName").value,
                lastName: document.getElementById("LastName").value,
                middleName: "",
                titleId: 1,
                cityId: 1,
                address1: document.getElementById("Address").value,
                address2: "",
                address3: "",
                phone1: document.getElementById("Telephone").value,
                phone2: "",
                email1: document.getElementById("Email").value,
                email1IsVerified: false,
                email2: "",
                email2IsVerified: false,
                migrationOldId: 0,
                additionalDetails: {
                    id: 0,
                    identificationTypeId1: 2,
                    identificationType1: document.getElementById("Identification").value == 'NOT DEFINED' ? '' : document.getElementById("Identification").value,
                    identificationTypeId2: 0,
                    identificationType2: "",
                    customerId: 0,
                    placeOfBirthId: 1,
                    nationalityId: 1,
                    birthDate: null,
                    bankId: -1,
                    bankAccountNumber: "",
                    gender: "F",
                    maritalStatusId: this.MaritalStatus,
                    spousesFullName: this.MaritalStatus == 1 || this.MaritalStatus == '1' ? this.SpoucesFullName : null,
                    existingBankDebt: this.SelectedBankEntities,
                    otherInstitutionName: this.OtherInstitutionName,
                    createdBy: 1,
                    createDate: "2024-04-16T04:00:06.366Z",
                    isActive: true,
                    CoApplicantIDNumber: document.getElementById("CoApplicantIdentification").value == 'NOT DEFINED' ? '' : document.getElementById("CoApplicantIdentification").value,
                    CoApplicantFirstName: document.getElementById("CoApplicantFirstName").value,
                    CoApplicantLastName: document.getElementById("CoApplicantLastName").value,
                    CoApplicantPhone1: document.getElementById("CoApplicantPhone1").value,
                },
                employments: {
                    id: 0,
                    employerName: document.getElementById("EmployeerName").value,
                    fromDate: "2024-01-01",
                    monthlySalary: 0,
                    customerId: 0,
                    clasificationId: 0,
                    isVerified: false,
                    createdBy: 1,
                    createDate: "2024-04-16T04:00:06.366Z",
                    isActive: true,
                },
                loanApplicationInfo: {
                    id: 0,
                    loanAmount: this.Amount,
                    periodInMonths: this.MonthSelectedOption,
                    monthlyPayment: this.MonthlyPayment,
                    agentId: this.user.Id,
                    emailSent: true,
                    loanTypeId: this.LoanType,
                    customerId: 0,
                    createdBy: 1,
                    isActive: true,
                    origin: 2,
                },
                createdBy: 1,
                createDate: "2024-04-16T04:00:06.366Z",
                isActive: true,
                origin: 0,
            };


            if (this.SelectedBankEntities >= 1) {
                payload.additionalDetails.existingDebtAmount = this.ExistingDebtAmount;
            }


            const profileAddUrl = "customers/Api/Profiles/Add";

            try {
                const response = await axios.post(profileAddUrl, payload)

                this.loadingData = false
                if (response.status == 200 || response.status == 201) {
                    this.sendLoanData(response.data.dataResult);
                }
                this.loadingData = false

            } catch (error) {
                this.$bvToast.toast(`Error creating Loan`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
                this.loadingData = false
            }


        },
        async GetMinAndMaxAmount() {
            const response = await axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetLoanRange?LoanType=${this.LoanType}`)
            if (response.status == 200) {
                var result = response.data;
                this.MinAmount = result.minAmount;
                this.MaxAmount = result.maxAmount;
                this.Amount = result.minAmount;
                this.GetMonths();
                if (this.MonthSelectedOption != null) this.GetMonthlyPayment();
            }
            else {
                this.$bvToast.toast(`An error has occurred in the get min and max amount.`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        async GetMonths() {
            const response = await axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetMonthsByLoanType?Amount=${this.Amount}&LoanType=${this.LoanType}`)
            if (response.status == 200) {
                this.MonthsArray = response.data;
                this.MonthSelectedOption = response.data[0];
                this.GetMonthlyPayment();
            }
            else {
                this.$bvToast.toast(`An error has occurred in the get months by loan type.`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        async GetMonthlyPayment() {
            this.Loading = true;
            const response = await axios.get(`${this.UrlServerIP}${this.UrlServerPORT}/Api/Calculator/GetMonthtlyPayment?Amount=${this.Amount}&Months=${this.MonthSelectedOption}&LoanType=${this.LoanType}`)
            if (response.status == 200) {
                var result = response.data;
                this.MonthlyPayment = result.amount;
            }
            else {
                this.$bvToast.toast(`An error has occurred in the get months by loan type endPoint.`, {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                });
            }
            this.Loading = false;
        },
        Debounce() {
            this.Loading = true;
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                if (this.Amount >= this.MinAmount && this.Amount <= this.MaxAmount) {
                    this.GetMonths();
                    this.GetMonthlyPayment();
                }
                else {
                    let LoanTypeDescription = this.LoanType == 1 ? "Personal" : "Vehicle";
                    if (this.Amount < this.MinAmount) {
                        this.$bvToast.toast(`Our ${LoanTypeDescription} Loans start at AWG ${this.MinAmount}.`, {
                            title: "Please!",
                            variant: "warning",
                            solid: true,
                        });
                        this.Amount = this.MinAmount;
                        this.GetMonths();
                        this.GetMonthlyPayment();
                    }
                    else if (this.Amount > this.MaxAmount) {
                        this.$bvToast.toast(`You have exceeded the maximum loan amount. Our ${LoanTypeDescription} Loans go up to ${this.MaxAmount}.`, {
                            title: "Please!",
                            variant: "warning",
                            solid: true,
                        });
                        this.Amount = this.MaxAmount;
                        this.GetMonths();
                        this.GetMonthlyPayment();
                    }
                }
            }, 1500);
        },
        async getCustomer() {
            let status = null;
            const response = await this.$store.dispatch("customers/get_allCustomers", { skip: 1, take: 20, status, query: this.query });

            this.customers = response?.dataResult ?? []
            this.options = this.customers.map(el => {
                return {
                    text: el.firstName,
                    value: el.id
                }
            })
        },
        async getCoApplicant() {
            const response = await this.$store.dispatch("customers/get_allCustomers", { skip: 1, take: 20, status: null, query: this.query });

            this.coApplicants = response?.dataResult ?? []
            this.optionsCoApp = this.coApplicants.map(el => {
                return {
                    text: el.firstName,
                    value: el.id
                }
            })
        },
        handleOption(item) {
            this.selectedOption = this.customers.find(el => el.id == item.value)
            if (this.selectedOption) {
                const customer = this.selectedOption;
                document.getElementById("Identification").value = customer.idNumber != null && customer.idNumber != 'NOT DEFINED' ? customer.idNumber : "";
                document.getElementById("FirstName").value = customer.firstName || "";
                document.getElementById("LastName").value = customer.lastName || "";
                document.getElementById("Email").value = customer.email1 || "";
                document.getElementById("Telephone").value = customer.phone1 || "";
                document.getElementById("Address").value = customer.address1 || "";
                document.getElementById("EmployeerName").value = customer.employer || "";
            }
        },
        handleOptionCoApplicant(item) {
            this.selectedOption = this.coApplicants.find(el => el.id == item.value)
            if (this.selectedOption) {
                document.getElementById("CoApplicantIdentification").value = this.selectedOption.idNumber || "";
                document.getElementById("CoApplicantFirstName").value = this.selectedOption.firstName || "";
                document.getElementById("CoApplicantLastName").value = this.selectedOption.lastName || "";
                document.getElementById("CoApplicantPhone1").value = this.selectedOption.phone1 || "";
            }
        },
        handleSearchInput(query) {
            this.query = query
            this.debouncedSearch()
        },
        handleSearchCoApplicant(query) {
            this.query = query
            this.debouncedSearchCoApp()
        }
    },
    created() {
        this.debouncedSearch = debounce(this.getCustomer, 300);
        this.debouncedSearchCoApp = debounce(this.getCoApplicant, 300);
    }
};
</script>
